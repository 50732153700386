import { PropsWithChildren } from 'react';

import { FeatureFlags } from '../../feature-flags';
import { Experiments } from '../Experiments';
import { ExperimentsContext } from '../ExperimentsContext';

export type FeatureFlag = keyof FeatureFlags;

export type ExperimentsProviderProps = {
  experiments: Experiments;
};

export const ExperimentsProvider = ({
  children,
  experiments,
}: PropsWithChildren<ExperimentsProviderProps>) => (
  <ExperimentsContext.Provider value={experiments}>{children}</ExperimentsContext.Provider>
);
