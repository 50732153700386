import { useSiteConfig } from '../../context/SiteConfigProvider';

export enum searchClickEventType {
  BASIC_EVENT = 'BASIC_EVENT',
  SEARCH_PAGE_CLICKED = 'SEARCH_PAGE_CLICKED',
}

export enum SearchResultPageType {
  ALLTAB = 'alles_page',
  CATEGORYTAB = 'category_page',
}

interface SearchClickPayload {
  application: string;
  userQuery: string;
  tab: string;
  sessionId: string;
  properties: {
    keywordResult: boolean;
    exactResult: boolean;
    semanticResult: boolean;
    pageNumber: number;
    position: number;
    docId: string;
  };
  eventType: searchClickEventType;
}

export const useTrackSearchResultClicks = () => {
  const { searchAnalyticsUrl } = useSiteConfig();

  const query = async function (url: string, body: SearchClickPayload) {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        accept: 'text/html',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...body,
      }),
    });

    return res;
  };

  const trackSearchResultClicks = async (payload: SearchClickPayload) => {
    if (!searchAnalyticsUrl) return;
    const res = await query(searchAnalyticsUrl, payload);

    if (!res.ok) {
      const error: Error = new Error('Unable to perform searchfeedback clickevent POST.');
      throw error;
    }
  };

  return {
    trackSearchResultClicks,
  };
};
