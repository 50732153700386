/* eslint-disable no-nested-ternary */
import type { SidebarItemProps } from '@elseu/sdu-titan';
import {
  BellOutlineIcon,
  BookmarkIcon,
  BookOpenedIcon,
  Clickable,
  ElementPropsProvider,
  Logo,
  Navigation as TitanNavigation,
  NavigationWidgetButton,
  Text,
  useSidebar,
} from '@elseu/sdu-titan';
import { useFeatureIsOn } from '@elseu/sdu-titan-experiments/feature-flags';
import { t, Trans } from '@lingui/macro';
import { useAuthentication } from '@repo/auth';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useNavigation } from '../../context/NavigationProvider/NavigationProvider';
import { useSiteConfig } from '../../context/SiteConfigProvider';
import { checkTrialHasEnded } from '../../helpers';
import { findDeep } from '../../helpers/findDeep';
import { useConsentManager } from '../../hooks/useConsentManager';
import { useIsAnonymousUser } from '../../hooks/useIsAnonymousUser';
import { logos } from '../Logos';
import { SkipLinks } from '../SkipLinks/SkipLinks';

const SidebarUser = dynamic(
  () => import(/* webpackChunkName: "SidebarUser" */ '../SidebarUser/SidebarUser'),
  {
    ssr: false,
  },
);
const SearchBarWithInPageSearch = dynamic(
  () =>
    import(
      /* webpackChunkName: "SearchBarWithInPageSearch" */ '../SearchBar/SearchBarWithInPageSearch'
    ),
  {
    ssr: false,
  },
);

const XposiNavigationButton = dynamic(
  () =>
    import(/* webpackChunkName: "XposiNavigationButton" */ '@elseu/sdu-titan-search').then(
      (module) => module.XposiNavigationButton,
    ),
  {
    ssr: false,
  },
);

const StyledClickable = styled(Clickable)`
  &:hover span,
  &:focus span {
    text-decoration: underline;
  }
`;

const CloseSidebarButton = () => {
  const { closeSidebar } = useSidebar();
  const { showConsentManager } = useConsentManager();

  return (
    <StyledClickable
      data-test-id="cookieSettingsButton"
      type="button"
      onClick={() => {
        closeSidebar?.();

        // wait until sidebar is closed
        setTimeout(() => {
          showConsentManager();
        }, 500);
      }}
    >
      <Text color="grey70" type="labelSmall">
        <Trans>Cookies wijzigen</Trans>
      </Text>
    </StyledClickable>
  );
};

const Navigation = () => {
  const { pathname, asPath, push: routerPush } = useRouter();

  const { brandName, logo, siteUrl, version, featureFlags, urls, shouldHideBrandName } =
    useSiteConfig();

  const isAnonymousUser = useIsAnonymousUser();
  const { navigation, isLoading, hasError, isShown, setShown } = useNavigation();
  const [activeSidebarId, setActiveSidebarId] = useState<string>();
  const { isLoggedIn, userInfo } = useAuthentication();

  const trialHasEnded = checkTrialHasEnded(userInfo?.trialEndDate);

  const isSearchHomePage = useMemo(
    () => pathname === '/' && featureFlags.WITH_HOMEPAGE_SEARCH,
    [pathname, featureFlags],
  );

  const withXposi = useFeatureIsOn('WITH_XPOSI');
  const withSources = useFeatureIsOn('WITH_SOURCES');
  const withPublicSite = useFeatureIsOn('WITH_PUBLIC_SITE');
  const withReadingList = useFeatureIsOn('WITH_READING_LIST');
  const withAlerts = useFeatureIsOn('WITH_ALERTS');
  const withSearch = useFeatureIsOn('WITH_SEARCH');

  useEffect(() => {
    if (!navigation) return;
    const activeItem = findDeep<SidebarItemProps>(navigation, 'to', asPath);
    if (activeItem) setActiveSidebarId(activeItem.id);
  }, [asPath, navigation]);

  const widgets: React.ReactElement[] = [];

  if (withXposi) {
    widgets.push(
      <XposiNavigationButton
        key="xposiWidget"
        aria-label={t`Xposi`}
        data-onboarding="xposi"
        data-test-id="xposiNavigationWidget"
        label={<Trans>Xposi</Trans>}
      />,
    );

    if (withSources) {
      widgets.push(
        <NavigationWidgetButton
          key="sourcesOverviewWidget"
          aria-label={t`Bronnenoverzicht`}
          data-onboarding="sources-overview"
          data-test-id="sourcesOverviewWidget"
          label={<Trans>Bronnen</Trans>}
          onClick={() => routerPush(urls.sourcesOverviewUrl)}
        >
          <BookOpenedIcon />
        </NavigationWidgetButton>,
      );
    }
  }

  if (!withPublicSite) {
    if (withReadingList) {
      widgets.push(
        <NavigationWidgetButton
          key="bookmarkWidget"
          aria-label={t`Leeslijsten`}
          data-onboarding="reading-lists"
          data-test-id="bookmarkWidget"
          label={<Trans>Leeslijsten</Trans>}
          onClick={() => routerPush(urls.readingListsUrl)}
        >
          <BookmarkIcon />
        </NavigationWidgetButton>,
      );
    }

    if (withAlerts) {
      widgets.push(
        <NavigationWidgetButton
          key="notificationWidget"
          aria-label={t`Attenderingen`}
          data-onboarding="notifications"
          data-test-id="notificationsWidget"
          label={<Trans>Attenderingen</Trans>}
          onClick={() => routerPush(urls.alertsUrl)}
        >
          <BellOutlineIcon />
        </NavigationWidgetButton>,
      );
    }
  }

  const brand =
    process.env.NODE_ENV !== 'production' ||
    siteUrl?.includes('test') ||
    siteUrl?.includes('ota') ||
    siteUrl?.includes('dev')
      ? `${brandName} ${version}`
      : brandName;

  return (
    <>
      <SkipLinks />
      <ElementPropsProvider
        elementProps={{
          sidebarMenuButton: {
            'data-onboarding': 'menu',
          },
        }}
      >
        <TitanNavigation
          isSticky
          activeSidebarId={activeSidebarId}
          avatar={!featureFlags.WITH_PUBLIC_SITE ? <SidebarUser /> : undefined}
          brandName={logo ? '' : brand}
          hasError={hasError}
          isLoading={isLoading}
          isSearchShown={withSearch && isShown && !isSearchHomePage && !trialHasEnded}
          labelHamburger={<Trans>Menu</Trans>}
          labelSidebarClose={t`Sluit menu`}
          labelSidebarOpen={t`Open menu`}
          logoComponent={
            isSearchHomePage ? (
              <></>
            ) : (
              <Logo
                brandName={shouldHideBrandName ? undefined : brand}
                svg={logo ? logos[logo] : undefined}
              />
            )
          }
          matchFunction={(href) => href === asPath}
          searchComponent={
            !isSearchHomePage && !trialHasEnded && withSearch ? (
              <SearchBarWithInPageSearch isShown={isShown} onToggle={setShown} />
            ) : undefined
          }
          sidebarItems={!trialHasEnded ? navigation : []}
          widgets={isLoggedIn && !isAnonymousUser && !trialHasEnded ? widgets : undefined}
          onToggleSearch={setShown}
        >
          <CloseSidebarButton />
        </TitanNavigation>
      </ElementPropsProvider>
    </>
  );
};

export { Navigation };
