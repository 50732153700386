import { decodeJwt } from 'jose';
export function getClaims(tokenSet) {
    let idTokenClaims = {};
    let accessTokenClaims = {};
    accessTokenClaims = decodeJwt(tokenSet.access_token);
    if (tokenSet.id_token) {
        idTokenClaims = decodeJwt(tokenSet.id_token);
    }
    const claims = Object.assign(Object.assign({}, idTokenClaims), accessTokenClaims);
    return {
        exp: claims.exp,
        sub: claims.sub,
        sid: claims.sid,
        amr: claims.amr,
        anon: claims.anon,
        externalId: claims.externalId,
        fed_id: claims.fed_id,
        idp: claims.idp,
        login: claims.login,
        MFA: claims.MFA,
        role: claims.role,
        support_url: claims.support_url,
        permissions: claims.permissions,
        organization: claims.organization,
    };
}
