import { checkTrialHasEnded } from '@elseu/sdu-titan-product-site-components';
import { useAuthentication } from '@repo/auth';

import { urls } from '@/helpers/urls';

import { Redirect } from './Redirect';

export function TrialPaywallRedirect() {
  const { userInfo } = useAuthentication();

  const trialHasEnded = checkTrialHasEnded(userInfo?.trialEndDate);

  return <Redirect condition={trialHasEnded} to={urls.trialPaywall!} />;
}
