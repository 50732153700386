import { useContext } from 'react';

import { ExperimentsContext } from '../ExperimentsContext';

export const useExperiments = () => {
  const experiments = useContext(ExperimentsContext);

  if (!experiments) {
    throw new Error('useExperiments must be used within ExperimentsProvider');
  }

  return experiments;
};
