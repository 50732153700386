import { useRouter } from 'next/router';
import { useEffect } from 'react';

interface RedirectProps {
  to: string;
  condition: boolean;
}

export function Redirect({ to, condition }: RedirectProps) {
  const router = useRouter();

  useEffect(() => {
    if (condition && router.pathname !== to) {
      router.push(to);
    }
  }, [condition, to, router]);

  return null;
}
