import { FeatureFlag } from './components/ExperimentsProvider';

/**
 * Class wrapper to manage experiment configs such as feature flags.
 */
export class Experiments {
  private readonly featureFlags: FeatureFlag[];

  /**
   * Creates a new instance of Experiments
   * @param featureFlags
   */
  constructor(featureFlags: FeatureFlag[]) {
    this.featureFlags = featureFlags;
  }

  /**
   * Checks if a feature is enabled
   *
   * @param feature
   */
  isOn(feature: FeatureFlag): boolean {
    return this.featureFlags.includes(feature);
  }
}
