import type { Urls } from '@elseu/sdu-titan-product-site-components';

const urls: Urls = {
  alertsUrl: '/attenderingen',
  bluetickUrl: '/bluetick',
  contentUrl: '/content',
  editionUrl: '/tijdschriften/:magazineId/:editionId',
  genialUrl: '/genial',
  genialHelpCenterUrl: '/basic/genial-helpcenter',
  magazinesUrl: '/tijdschriften',
  magazineUrl: '/tijdschriften/:magazineId',
  newsOverviewUrl: '/nieuws',
  newsSourceUrl: '/nieuws/:sourceId',
  pnNavigationUrl: '/overzicht/:slug/toc/:documentKey/:subId',
  readingListsUrl: '/leeslijsten',
  searchUrl: '/zoeken',
  sourcesOverviewUrl: '/bronnenoverzicht',
  trialPaywall: '/genial/proefperiode-verlopen',
  trialRestrictedAccess: '/genial/proefperiode-geen-toegang',
};

export { urls };
