import { PropsWithChildren, ReactNode } from 'react';

import { FeatureFlag } from '../../experiments';
import { useFeatureIsOn } from '../hooks/useFeatureIsOn';

type IsFeatureFlagOnProps = {
  feature: FeatureFlag;
  alternative?: ReactNode | null;
};

/**
 * Conditionally renders children based on the feature flag.
 * You can also provide an alternative to be rendered when the feature flag is not enabled.
 *
 * @param feature
 * @param children
 * @param alternative
 * @constructor
 */
export const IfFeatureEnabled = ({
  feature,
  children,
  alternative = null,
}: PropsWithChildren<IsFeatureFlagOnProps>) => {
  const isOn = useFeatureIsOn(feature);

  return isOn ? children : alternative;
};
