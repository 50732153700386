import { FeatureFlag } from '../../experiments';
import { useExperiments } from '../../experiments/hooks/useExperiments';

/**
 * Hook to check if a feature flag is enabled.
 *
 * @param flag
 */
export const useFeatureIsOn = (flag: FeatureFlag): boolean => {
  const experiments = useExperiments();

  return experiments.isOn(flag);
};
