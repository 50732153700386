var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { decodeJwt } from 'jose';
import { Issuer, } from 'openid-client';
import { getClaims } from './claims';
import { isRechtsordeClaims } from './helpers/isRechtsordeClaims';
import { mappings } from './mappings';
export const DEFAULT_AUTH_PROVIDER = 'oauth';
export function createNextAuthOptions({ clientId, clientSecret, scope, discoveryUrl, clientAuthMethod = 'client_secret_post', signInPage = '/auth/signin', checks = ['pkce', 'state'], extendProfile, }) {
    const clientMetaData = {
        token_endpoint_auth_method: clientAuthMethod,
    };
    const provider = {
        type: 'oauth',
        id: DEFAULT_AUTH_PROVIDER,
        name: 'OAuth',
        clientId,
        clientSecret,
        wellKnown: discoveryUrl,
        issuer: discoveryUrl && new URL(discoveryUrl).origin,
        client: clientMetaData,
        authorization: {
            params: {
                scope,
            },
        },
        idToken: true,
        checks,
        profile(profile, tokenSet) {
            return __awaiter(this, void 0, void 0, function* () {
                const extraParams = extendProfile ? yield extendProfile(profile, tokenSet) : {};
                const isRechtsorde = isRechtsordeClaims(profile);
                const info = isRechtsorde
                    ? mappings.rechtsorde(profile, tokenSet)
                    : mappings.ping(profile, tokenSet);
                return Object.assign(Object.assign({}, info), extraParams);
            });
        },
    };
    function getClient() {
        return __awaiter(this, void 0, void 0, function* () {
            const issuer = yield Issuer.discover(discoveryUrl);
            const client = new issuer.Client(Object.assign({ client_id: clientId, client_secret: clientSecret }, clientMetaData));
            return client;
        });
    }
    /**
     * Takes a token, and returns a new token with updated
     * `accessToken` and `accessTokenExpires`. If an error occurs,
     * returns the old token and an error property
     */
    function refreshAccessToken(token) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const client = yield getClient();
                const refreshToken = token.refreshToken;
                if (!refreshToken) {
                    throw new Error('Refresh token is required');
                }
                const isPost = clientAuthMethod === 'client_secret_post';
                const params = isPost
                    ? {
                        exchangeBody: {
                            scope: scope,
                        },
                    }
                    : undefined;
                const tokenSet = yield client.refresh(refreshToken, params);
                let userInfo = token.userInfo;
                if (tokenSet.id_token) {
                    const idTokenClaims = decodeJwt(tokenSet.id_token);
                    userInfo = yield provider.profile(idTokenClaims, tokenSet);
                }
                return Object.assign(Object.assign({}, token), { accessToken: tokenSet.access_token, accessTokenExpires: tokenSet.expires_at * 1000, idToken: (_a = tokenSet.id_token) !== null && _a !== void 0 ? _a : token.idToken, refreshToken: (_b = tokenSet.refresh_token) !== null && _b !== void 0 ? _b : refreshToken, userInfo });
            }
            catch (error) {
                console.error('Error refreshing access token:', error);
                return Object.assign(Object.assign({}, token), { refreshToken: undefined, error: 'RefreshAccessTokenError' });
            }
        });
    }
    return {
        // Configure one or more authentication providers
        providers: [provider],
        pages: {
            signIn: signInPage,
        },
        callbacks: {
            jwt({ token, account, user }) {
                // Initial sign in
                if (account && user) {
                    return Object.assign(Object.assign({}, token), { userInfo: user, idToken: account.id_token, accessToken: account.access_token, accessTokenExpires: account.expires_at * 1000, refreshToken: account.refresh_token });
                }
                const isValid = Date.now() < token.accessTokenExpires;
                // Return previous token if the access token has not expired yet
                if (isValid) {
                    delete token.error;
                    return token;
                }
                if (token.refreshToken) {
                    return refreshAccessToken(token);
                }
                throw new Error('Access token has expired');
            },
            session({ session, token }) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (token) {
                        session.idToken = token.idToken; // required for logout logic
                        session.accessToken = token.accessToken;
                        session.error = token.error;
                        session.userInfo = token.userInfo;
                        if (token.idToken) {
                            const tokenSet = {
                                id_token: token.idToken,
                                access_token: token.accessToken,
                            };
                            session.claims = getClaims(tokenSet);
                        }
                    }
                    return session;
                });
            },
            redirect({ url, baseUrl }) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (url.startsWith(baseUrl)) {
                        const parseUrl = new URL(url);
                        // Remove forceAuth query parameter otherwise we will end up in a redirect loop
                        if (parseUrl.searchParams.has('forceAuth')) {
                            parseUrl.searchParams.delete('forceAuth');
                        }
                        return parseUrl.toString();
                    }
                    /**
                     * Federated logout
                     * makes sure that you are also logged out in the oidc provider
                     */
                    if (url.startsWith('signOut')) {
                        const parseUrl = new URL(`/${url}`, baseUrl);
                        const postLogoutRedirectUri = new URL(baseUrl);
                        if (parseUrl.searchParams.has('previous_page')) {
                            postLogoutRedirectUri.pathname = parseUrl.searchParams.get('previous_page');
                        }
                        const client = yield getClient();
                        const ssoLogoutUrl = client.endSessionUrl({
                            id_token_hint: parseUrl.searchParams.get('id_token_hint'),
                            post_logout_redirect_uri: postLogoutRedirectUri.toString(),
                        });
                        return ssoLogoutUrl.toString();
                    }
                    // Allows relative callback URLs
                    if (url.startsWith('/'))
                        return new URL(url, baseUrl).toString();
                    return baseUrl;
                });
            },
        },
    };
}
