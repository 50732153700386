import type { OnCompleteFilterState, TabWithKeys } from '@elseu/sdu-titan-search';
import { Order } from '@elseu/sdu-titan-search';
import type { SearchContextValue } from '@elseu/sdu-titan-search/dist/types/context/search/types';

import { SearchResultPageType } from '../../helpers/analytics/useTrackSearchResultClicks';

type AnalyticsEventType = 'track' | 'page' | 'identify';

interface AnalyticsEventPayload<T extends AnalyticsEventType, E, P> {
  type: T;
  event: E;
  properties: P;
  meta: {
    timestamp: string;
    callback: () => void;
  };
}

export enum SearchEvent {
  SEARCH = 'site_search',
  /** This event will be replaced with SEARCH_RESULTS with filter interaction type */
  SEARCH_FILTER = 'search_result_filtering',
  /** This event will be made redundant by alltab block queries */
  SEARCH_FILTERS_CLEARED = 'search_result_filters_cleared',
  SEARCH_RESULTS = 'search_results',
  SEARCH_RESULT_CLICK = 'search_result_click',
  /** This event will be replaced with SEARCH_RESULTS with sort interaction type */
  SEARCH_SORT = 'search_result_sorting',
}

export enum SearchInteractionType {
  SEARCH_QUERY = 'search_query',
  SEARCH_FILTERED = 'search_filtered',
  SEARCH_PAGE_CHANGE = 'page_change',
  SEARCH_RESULT_CLICK = 'search_result_click',
  SEARCH_SORTED = 'search_sorted',
}

export interface SearchSorting {
  [key: string]: Order;
}
export interface SearchFilters {
  [facetName: string]: string[];
}
export interface SearchPayload {
  search_terms: string[]; // always array for search terms e.g. ['civiele', 'rechter']
  search_results_total: number; // total number of search results
  search_results_page: number; // the page you are at in pagination
  search_results_first_page: number; // number of results on first page, usually 10
  element_interacted_with: string | null; // e.g. 'ro_year'
  search_filters: SearchFilters | null; // active filters - (note that the Tab 'Alles' is not considered a filter)
  search_sorting: SearchSorting | null; // active sorting mechnism
}
export interface SearchResultPayload extends SearchPayload {
  eventName: string;
  swsDocumentTitle: string;
  swsTypes: string[];
  swsId: string;
  swsAltKeys: string[];
  swsPublicationYear: string;
  swsPublicationId: string;
  swsSource: string[];
  swsArticleNumbers: string[];
  swsPublisher: string;
}

/**
 * typings for "search_results" and "search_result_click" events"
 */
export interface SearchPayloadCommon {
  search_interaction_type: string;
  search_terms: string[];
  search_filters: SearchFilters | null;
  search_sorting: SearchSorting | null;
  ai_assisted: boolean;
  search_results_page_type: string;
  search_results_page_number: number;
}
export interface SearchResultsPayload extends SearchPayloadCommon {
  search_results_page_number_max: number;
  search_results_count_page: number;
  search_results_count_max: number;
  search_results_sws_ids: string[];
}
export interface SearchResultClickPayload extends SearchPayloadCommon {
  search_result_clicked_position: number;
  sws_alt_keys: string[];
  sws_article_numbers: string[];
  sws_document_title: string;
  sws_id: string;
  sws_publication_id: string;
  sws_publication_year: string;
  sws_publisher: string;
  sws_source: string[];
  sws_types: string[];
}

type SearchEventPayload = AnalyticsEventPayload<'track', SearchEvent.SEARCH, SearchPayload>;
type SearchSortEventPayload = AnalyticsEventPayload<
  'track',
  SearchEvent.SEARCH_SORT,
  SearchPayload
>;
type SearchFilterEventPayload = AnalyticsEventPayload<
  'track',
  SearchEvent.SEARCH_FILTER,
  SearchPayload
>;
type SearchFilterResetEventPayload = AnalyticsEventPayload<
  'track',
  SearchEvent.SEARCH_FILTERS_CLEARED,
  SearchPayload
>;
type SearchResultClickEventPayload = AnalyticsEventPayload<
  'track',
  SearchEvent.SEARCH_RESULT_CLICK,
  SearchResultPayload
>;

export type AnalyticsEventPayloads =
  | SearchEventPayload
  | SearchSortEventPayload
  | SearchFilterEventPayload
  | SearchFilterResetEventPayload
  | SearchResultClickEventPayload;

/**
 * Payload transformation helpers
 */
const getSelectedSearchSorting = ({ filters }: OnCompleteFilterState): SearchSorting => {
  return { [filters.sort.by]: filters.sort.order || Order.Descending };
};

const getSelectedSearchFilters = (
  state: OnCompleteFilterState,
  tabsFacet: string,
  tabs: TabWithKeys[],
): Record<string, string[]> => {
  const entries = Object.entries(state.facetFilters)
    .filter(([, facet]) => facet.length > 0)
    .map(([key, facet]) => {
      if (key === tabsFacet) {
        const tab = tabs.find((tab) => facet.every((name) => tab.keys.includes(name)));

        if (tab) {
          return [key, [tab.label]];
        }
      }

      return [key, facet];
    });

  return Object.fromEntries(entries);
};

const getSearchAnalyticsPayload = (
  searchResults: SearchContextValue['searchResults'],
  state: OnCompleteFilterState,
  dynamicProps?: Pick<
    SearchPayload,
    'element_interacted_with' | 'search_filters' | 'search_sorting'
  >,
): SearchPayload => {
  const { size, page, query } = state.filters;

  return {
    search_terms: query ? query.split(' ') : [],
    search_results_total: searchResults?.totalCount,
    search_results_page: page,
    search_results_first_page: size,
    element_interacted_with: null,
    search_filters: null,
    search_sorting: null,
    ...(dynamicProps || {}),
  };
};

const getNewQueryAnalyticsPayload = (
  searchResults: SearchContextValue['searchResults'],
  state: OnCompleteFilterState,
) => {
  return getSearchAnalyticsPayload(searchResults, state);
};

const getSortAnalyticsPayload = (
  searchResults: SearchContextValue['searchResults'],
  state: OnCompleteFilterState,
  tabsFacet: string,
  tabs: TabWithKeys[],
) => {
  const search_filters = getSelectedSearchFilters(state, tabsFacet, tabs);
  const search_sorting = getSelectedSearchSorting(state);

  const dynamicProps: Pick<
    SearchPayload,
    'element_interacted_with' | 'search_filters' | 'search_sorting'
  > = {
    element_interacted_with: state.lastAction?.name || null,
    search_filters,
    search_sorting,
  };

  return getSearchAnalyticsPayload(searchResults, state, dynamicProps);
};

const getFilterAnalyticsPayload = (
  searchResults: SearchContextValue['searchResults'],
  state: OnCompleteFilterState,
  tabsFacet: string,
  tabs: TabWithKeys[],
) => {
  const search_filters = getSelectedSearchFilters(state, tabsFacet, tabs);
  const search_sorting = getSelectedSearchSorting(state);

  const dynamicProps: Pick<
    SearchPayload,
    'element_interacted_with' | 'search_filters' | 'search_sorting'
  > = {
    element_interacted_with: state.lastAction?.name || null,
    search_filters,
    search_sorting,
  };

  return getSearchAnalyticsPayload(searchResults, state, dynamicProps);
};

const getFiltersClearedAnalyticsPayload = (
  searchResults: SearchContextValue['searchResults'],
  state: OnCompleteFilterState,
  tabsFacet: string,
  tabs: TabWithKeys[],
) => {
  const search_filters = getSelectedSearchFilters(state, tabsFacet, tabs);
  const search_sorting = getSelectedSearchSorting(state);

  const dynamicProps: Pick<
    SearchPayload,
    'element_interacted_with' | 'search_filters' | 'search_sorting'
  > = {
    element_interacted_with: null,
    search_filters,
    search_sorting,
  };

  return getSearchAnalyticsPayload(searchResults, state, dynamicProps);
};

type ExtraVariables = Pick<
  SearchResultPayload,
  | 'element_interacted_with'
  | 'search_results_total'
  | 'eventName'
  | 'swsId'
  | 'swsTypes'
  | 'swsDocumentTitle'
  | 'swsAltKeys'
  | 'swsPublicationYear'
  | 'swsPublicationId'
  | 'swsSource'
  | 'swsArticleNumbers'
  | 'swsPublisher'
>;

type ResultClickProps = Pick<
  SearchResultClickPayload,
  | 'search_results_page_type'
  | 'search_results_page_number'
  | 'search_result_clicked_position'
  | 'sws_alt_keys'
  | 'sws_article_numbers'
  | 'sws_id'
  | 'sws_document_title'
  | 'sws_publication_id'
  | 'sws_publication_year'
  | 'sws_publisher'
  | 'sws_source'
  | 'sws_types'
>;

const getSearchResultClickAnalyticsPayload = (
  state: OnCompleteFilterState,
  extraVariables: ExtraVariables,
  tabsFacet: string,
  tabs: TabWithKeys[],
) => {
  const { size, page, query } = state.filters;

  const search_filters = getSelectedSearchFilters(state, tabsFacet, tabs);
  const search_sorting = getSelectedSearchSorting(state);

  const payload: SearchResultPayload = {
    search_terms: query ? query.split(' ') : [],
    search_filters,
    search_sorting,
    search_results_page: page,
    search_results_first_page: size,
    ...extraVariables,
  };

  return payload;
};

/**
 * Common payload for search_results and search_result_click events
 */
const getCommonPayload = (state: OnCompleteFilterState, tabsFacet: string, tabs: TabWithKeys[]) => {
  const { query } = state.filters;
  const search_filters = getSelectedSearchFilters(state, tabsFacet, tabs);
  const search_sorting = getSelectedSearchSorting(state);

  return {
    search_terms: query ? query.split(' ') : [],
    search_filters,
    search_sorting,
    ai_assisted: false,
  };
};

const getResultsAnalyticsPayload = (
  searchResults: SearchContextValue['searchResults'],
  state: OnCompleteFilterState,
  tabsFacet: string,
  tabs: TabWithKeys[],
  searchInteractionType: SearchInteractionType,
) => {
  const { size, page } = state.filters;
  const sws_ids = searchResults?.results.map((result) => result.id);
  // @ts-expect-error fix this typing
  const isCategoryTab = state.facetFilters[tabsFacet].length > 0;

  const payload: SearchResultsPayload = {
    search_interaction_type: searchInteractionType,
    search_results_page_type: isCategoryTab
      ? SearchResultPageType.CATEGORYTAB
      : SearchResultPageType.ALLTAB,
    search_results_page_number: page,

    search_results_page_number_max: ~~(searchResults?.totalCount / size),
    search_results_count_page: searchResults?.results.length || 0,
    search_results_count_max: searchResults?.totalCount,
    search_results_sws_ids: sws_ids || [],
    ...getCommonPayload(state, tabsFacet, tabs),
  };

  return payload;
};

const getResultClickAnalyticsPayload = (
  state: OnCompleteFilterState,
  resultClickProps: ResultClickProps,
  tabsFacet: string,
  tabs: TabWithKeys[],
) => {
  const payload: SearchResultClickPayload = {
    search_interaction_type: SearchEvent.SEARCH_RESULT_CLICK,
    ...resultClickProps,
    ...getCommonPayload(state, tabsFacet, tabs),
  };

  return payload;
};

export {
  getFilterAnalyticsPayload,
  getFiltersClearedAnalyticsPayload,
  getNewQueryAnalyticsPayload,
  getResultClickAnalyticsPayload,
  getResultsAnalyticsPayload,
  getSearchResultClickAnalyticsPayload,
  getSortAnalyticsPayload,
};
