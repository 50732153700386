import 'regenerator-runtime/runtime';

import type { ApolloError, InMemoryCache } from '@apollo/client';
import { useNextLinkResolver, useNextLinkWrapper } from '@elseu/sdu-next-utils';
import { ThemeProvider } from '@elseu/sdu-titan';
import { ContentExportProvider } from '@elseu/sdu-titan-content-export-react';
import { ExperimentsProvider } from '@elseu/sdu-titan-experiments/experiments';
import { GenIALProvider } from '@elseu/sdu-titan-genial';
import {
  AlertsProvider,
  AnalyticsProvider,
  AuthComponents,
  BaseLayout,
  BaseLayoutSimple,
  constructSearchUrlResolver,
  ContentRenderingProviderWithPlugins,
  ErrorBoundary,
  ErrorBoundaryPageFallback,
  GlobalSiteStyling,
  InPageSearchProviderWithRouter,
  NavigationProvider,
  OfficeDocsProvider,
  renderReadingListButtonFunction,
  renderSourceOverviewAlertColumn,
  sentryDialogOptions,
  SiteConfigProvider,
  TitanCmsProvider,
  TranslationsProvider,
  useApolloCacheControl,
  useNavigation,
  useSiteConfig,
  withApolloServerSideRender,
  withBot,
  withCookies,
  withLingui,
  withServerContext,
} from '@elseu/sdu-titan-product-site-components';
import { ReadingListsProvider } from '@elseu/sdu-titan-reading-lists-react';
import {
  SearchAutocompleteProvider,
  SearchRootProvider,
  XposiProvider,
} from '@elseu/sdu-titan-search';
import { type Messages } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { AuthenticationProvider, useAuthInitialization } from '@repo/auth';
import * as Sentry from '@sentry/nextjs';
import type { ScopeContext } from '@sentry/types';
import type { AppProps } from 'next/app';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import { DefaultSeo } from 'next-seo';
import { useCallback, useMemo } from 'react';

import { GlobalRedirects } from '@/components/GlobalRedirects/GlobalRedirects';
import { useTaxviceSharePlugin } from '@/components/TaxviceSharePlugin/hooks/useTaxviceSharePlugin';
import { config } from '@/config';
import { experiments } from '@/experiments';
import { getDefaultSeo } from '@/helpers/seo';
import { urls } from '@/helpers/urls';
import { loadMessages } from '@/translations/utils';

const isAuthenticationRequired = experiments.isOn('WITH_FORCED_AUTHENTICATION');
const isPublicSite = experiments.isOn('WITH_PUBLIC_SITE');

const useLinkWrapper = () => {
  const router = useRouter();
  return useNextLinkWrapper({
    nextVersion: 14,
    pathname: router.pathname,
    linkComponent: NextLink,
  });
};

const useLinkResolver = () => {
  const router = useRouter();
  return useNextLinkResolver({
    push: router.push,
  });
};

const handleGenIALError = (error: Error, options?: Partial<ScopeContext> | undefined) => {
  Sentry.withScope(function (scope) {
    if (options?.level) {
      scope.setLevel(options.level);
    }
    Sentry.captureException(error, {
      ...options,
      extra: {
        ...(options ? options.extra : {}),
        errorType: 'NextJS helpers/errorHandling',
      },
    });
  });
};

function AppProvidersWithAuth({ children }: React.PropsWithChildren) {
  const ApolloCacheControl = useApolloCacheControl();
  const { i18n } = useLingui();
  const router = useRouter();

  const {
    applicationKey,
    autocompleteList,
    bluetickGraphqlUrl,
    brandName,
    completemeGraphqlUrl,
    facetsPreset,
    featureFlags,
    graphqlUrl,
    logo,
    officeDocsGraphqlUrl,
    printGraphqlUrl,
    searchFeedbackUrl,
    searchGraphqlUrl,
    searchTabs,
    siteKey,
    urls,
    genialApiUrl,
    userPreferencesGraphqlUrl,
    userSourcesGraphqlUrl,
    xposiApiUrl,
    xposiUserContextUrl,
  } = useSiteConfig();

  const authenticationProps = useAuthInitialization({
    isAuthenticationRequired,
    shouldForceAuthentication: router.query.forceAuth === 'true',
    isSilentAuthEnabled: !isPublicSite,
    idp: router.query.idp as string,
  });

  const {
    claims,
    getAccessToken: accessToken,
    userInfo,
    authorize,
    logout,
    isInitialized,
    isLoggedIn,
  } = authenticationProps;

  const userId = userInfo?.sub;
  const isAnonymousUser = !!claims?.anon;

  const registerCacheInstanceContentRendering = useCallback(
    (cache: InMemoryCache) => {
      ApolloCacheControl.registerCache('content-rendering-provider', cache);
    },
    [ApolloCacheControl],
  );

  const registerCacheInstanceTitanCms = useCallback(
    (cache: InMemoryCache) => {
      ApolloCacheControl.registerCache('titan-cms-provider', cache);
    },
    [ApolloCacheControl],
  );

  const handlePrintErrors = useCallback(
    (type: 'mutation' | 'subscription', error?: ApolloError) => {
      if (!error) return;

      Sentry.captureException(error, {
        extra: {
          errorType: `ContentExportApi${type.charAt(0).toUpperCase() + type.slice(1)}Error`,
          errorMessage: error.message,
        },
      });
    },
    [],
  );

  const taxviceSharePlugin = useTaxviceSharePlugin();

  const dockItems = useMemo(
    () => (config.featureFlags.includes('WITH_TAXVICE_SHARING') ? [taxviceSharePlugin] : []),
    [taxviceSharePlugin],
  );

  const {
    alertsUrl,
    contentUrl,
    editionUrl,
    magazineUrl,
    magazinesUrl,
    newsOverviewUrl,
    newsSourceUrl,
    pnNavigationUrl,
    readingListsUrl,
    searchUrl,
    bluetickUrl,
  } = urls;

  const contentRenderingUrls = {
    alertsUrl,
    contentUrl,
    editionUrl,
    magazinesUrl,
    magazineUrl,
    newsOverviewUrl,
    newsSourceUrl,
    pnNavigationUrl,
    readingListsUrl,
    searchUrl,
    bluetickUrl: config.featureFlags.includes('WITH_BLUETICK_PASSAGES') ? bluetickUrl : undefined,
  };

  const { setShown } = useNavigation();

  const searchUrlResolver = useMemo(
    () =>
      constructSearchUrlResolver({
        searchUrl: urls.searchUrl,
        magazineUrl: urls.magazineUrl,
        tabs: searchTabs,
        tabsFacet: facetsPreset.tabsFacet,
      }),
    [urls.searchUrl, urls.magazineUrl, searchTabs, facetsPreset.tabsFacet],
  );

  return (
    <ErrorBoundary
      showDialog
      dialogOptions={sentryDialogOptions}
      fallback={<ErrorBoundaryPageFallback isFullScreen brandName={brandName} logo={logo} />}
    >
      <AuthenticationProvider {...authenticationProps}>
        <GlobalRedirects />
        <AnalyticsProvider>
          <ContentExportProvider
            accessToken={accessToken}
            applicationKey={applicationKey}
            contentGraphqlUrl={graphqlUrl}
            exportGraphqlUrl={printGraphqlUrl}
            siteName={brandName}
            urls={contentRenderingUrls}
            onError={handlePrintErrors}
          >
            <ReadingListsProvider
              hasExternalI18nProvider
              accessToken={accessToken}
              applicationKey={applicationKey}
              baseUrl={urls.readingListsUrl}
              documentUrl={urls.contentUrl}
              graphqlUrl={userPreferencesGraphqlUrl}
              hasUserSearch={
                featureFlags.WITH_READING_LIST_USER_SEARCH &&
                !!userInfo?.readingListAutocompleteEnabled
              }
              siteId={siteKey}
              userId={userId}
            >
              <SearchRootProvider
                accessToken={accessToken}
                applicationKey={applicationKey}
                bluetickGraphqlUrl={bluetickGraphqlUrl}
                bluetickUri={urls.bluetickUrl}
                completemeGraphqlUrl={completemeGraphqlUrl}
                contentBaseUri={urls.contentUrl}
                hasSimilarRulings={featureFlags.WITH_SIMILAR_RULINGS}
                isAnonymousUser={isAnonymousUser}
                locale={i18n.locale}
                renderReadingListButton={renderReadingListButtonFunction}
                renderSourceOverviewAlertColumn={
                  featureFlags.WITH_SOURCES && featureFlags.WITH_ALERTS
                    ? renderSourceOverviewAlertColumn
                    : undefined
                }
                roles={claims?.role}
                searchBaseUri={urls.searchUrl}
                searchFeedbackUrl={searchFeedbackUrl}
                searchGraphqlUrl={searchGraphqlUrl}
                searchUrlResolver={searchUrlResolver}
                siteId={siteKey}
                tabsFacet={facetsPreset.tabsFacet}
                userEmail={userInfo?.email}
                userId={userId}
                userPreferencesUrl={userPreferencesGraphqlUrl}
                userSourcesUrl={userSourcesGraphqlUrl}
                xposiApiUrl={xposiApiUrl}
                xposiUserContextUrl={xposiUserContextUrl}
              >
                <GenIALProvider
                  signIn={authorize}
                  signOut={logout}
                  accessToken={accessToken}
                  isInitialized={isInitialized}
                  isLoggedIn={isLoggedIn}
                  username={userInfo?.givenName}
                  applicationKey={applicationKey}
                  genialApiUrl={genialApiUrl!}
                  hasAccess={!!userInfo?.hasGenialAccess}
                  isEnabled={!!featureFlags.WITH_BLUETICK_AI}
                  withChatFeature={!!featureFlags.WITH_BLUETICK_AI_CHAT}
                  withOtherSourcesFeature={!!featureFlags.WITH_BLUETICK_AI_OTHER_SOURCES}
                  withUploadFileFeature={!!featureFlags.WITH_BLUETICK_AI_UPLOAD_FILE}
                  setNavigationShown={setShown}
                  onGenIALContextError={handleGenIALError}
                  trialEndDate={userInfo?.trialEndDate}
                  trialRestrictedAccessUrl={urls.trialRestrictedAccess}
                >
                  <AlertsProvider>
                    <ContentRenderingProviderWithPlugins
                      apolloInitialState={ApolloCacheControl.getExtractedCache(
                        'content-rendering-provider',
                      )}
                      apolloRegisterCache={registerCacheInstanceContentRendering}
                      brandingKey={config.brandingKey}
                      dockItems={dockItems}
                      roles={claims?.role}
                      sub={userInfo?.sub}
                      urls={contentRenderingUrls}
                    >
                      <OfficeDocsProvider
                        accessToken={accessToken}
                        graphqlUrl={officeDocsGraphqlUrl}
                      >
                        <TitanCmsProvider
                          apolloInitialState={ApolloCacheControl.getExtractedCache(
                            'titan-cms-provider',
                          )}
                          apolloRegisterCache={registerCacheInstanceTitanCms}
                        >
                          <SearchAutocompleteProvider list={autocompleteList}>
                            <NavigationProvider>
                              <XposiProvider>
                                <InPageSearchProviderWithRouter>
                                  {children}
                                </InPageSearchProviderWithRouter>
                              </XposiProvider>
                            </NavigationProvider>
                          </SearchAutocompleteProvider>
                        </TitanCmsProvider>
                      </OfficeDocsProvider>
                    </ContentRenderingProviderWithPlugins>
                  </AlertsProvider>
                </GenIALProvider>
              </SearchRootProvider>
            </ReadingListsProvider>
          </ContentExportProvider>
          <AuthComponents />
        </AnalyticsProvider>
      </AuthenticationProvider>
    </ErrorBoundary>
  );
}
interface AppProvidersProps {
  hasSimpleLayout?: boolean;
  router: AppProps['router'];
}

const pagesExcludedFromOnboarding = [
  // not displaying onboarding modals on GenIA-L page due to it having its own onboarding
  urls.genialUrl,
  urls.genialHelpCenterUrl!,
];

function AppProviders({ children, hasSimpleLayout }: React.PropsWithChildren<AppProvidersProps>) {
  if (hasSimpleLayout) {
    return <BaseLayoutSimple>{children}</BaseLayoutSimple>;
  }

  return (
    <AppProvidersWithAuth>
      <BaseLayout pagesExcludedFromOnboarding={pagesExcludedFromOnboarding}>{children}</BaseLayout>
    </AppProvidersWithAuth>
  );
}
interface AppHocProps {
  messages: Messages;
  isBot: boolean;
  cookies: {
    [key: string]: string;
  };
}

const App = ({
  Component,
  pageProps: { session, ...pageProps },
  cookies,
  messages,
  isBot,
  router,
}: AppProps & AppHocProps) => {
  return (
    <SessionProvider
      refetchOnWindowFocus={false}
      refetchInterval={
        /** 59 minutes, in case jwt token is valid for 1 hour */
        60 * 59
      }
      session={session}
    >
      <TranslationsProvider messages={messages}>
        <DefaultSeo {...getDefaultSeo(config, router.locale)} />
        <ThemeProvider
          defaultYOffset={26}
          designTokens={config.designTokens}
          useLinkResolver={useLinkResolver}
          useLinkWrapper={useLinkWrapper}
        >
          <Sentry.ErrorBoundary
            showDialog
            dialogOptions={sentryDialogOptions}
            fallback={
              <ErrorBoundaryPageFallback
                isFullScreen
                brandName={config.brandName}
                logo={config.logo}
              />
            }
          >
            <ExperimentsProvider experiments={experiments}>
              <GlobalSiteStyling />
              <SiteConfigProvider
                config={{
                  ...config,
                  isBot,
                  urls,
                }}
                cookies={cookies}
              >
                <AppProviders hasSimpleLayout={pageProps.hasSimpleLayout} router={router}>
                  <Component {...pageProps} />
                </AppProviders>
              </SiteConfigProvider>
            </ExperimentsProvider>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </TranslationsProvider>
    </SessionProvider>
  );
};

const EnhancedApp: ReturnType<typeof withApolloServerSideRender> = withApolloServerSideRender(
  withServerContext<any, AppProps & AppHocProps>(
    withLingui(withCookies(withBot(App)), loadMessages),
  ),
);

export default EnhancedApp;
